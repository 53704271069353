import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import img from "../../images/home/cantali-bisiklet.png";
import { colorPalette } from "../colors";

const styles = (theme) => ({
  root: {
    color: "#fff",
    backgroundImage: `url('${img}')`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "10vw 15vw 10vw 15vw",
    textAlign: "center",
    fontSize: "6.55vw",
  },

  container: {
    display: "flex",
  },

  dc: {
    fontWeight: 800,
  },

  filler: {
    flexGrow: 1,
  },

  number: {
    fontWeight: 800,
    color: colorPalette.orange,
  },

  heading: {
    fontWeight: 500,
    fontSize: "4vw",
    lineHeight: "2vw",
  },

  left: {
    textAlign: "left",
  },

  right: {
    textAlign: "right",
  },
});
const Decathlonluyuz = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.container}>
      <div className={classes.left}>
        <div className={classes.heading}>DÜNYADA</div>
        <div className={classes.number}>103000</div>
      </div>
      <div className={classes.filler} />
      <div className={classes.right}>
        <div className={classes.heading}>TÜRKİYE'DE</div>
        <div className={classes.number}>2500</div>
      </div>
    </div>
    <div className={classes.dc}>DECATHLONLUYUZ</div>
  </div>
);

export default withStyles(styles)(Decathlonluyuz);
