import React, { useEffect, useRef } from "react";
import { Expo, TweenMax } from "gsap";
import "./Potansiyelini.css";
import playImg from "../../images/home/play.png";
import ButtonBase from "@material-ui/core/ButtonBase";

export default ({ setOpen }) => {
  const potansiyeliniRef = useRef();

  useEffect(function () {
    const svg = potansiyeliniRef.current;
    const rects = svg.querySelectorAll("rect");
    const poly = svg.querySelectorAll("path");
    const polygon = svg.querySelectorAll("polygon");
    const polyline = svg.querySelectorAll("polyline");
    const path = svg.querySelectorAll("path");
    const arr = [path, poly, rects];
    TweenMax.set(arr, { opacity: 0 });
    TweenMax.set(path, {
      strokeDasharray: 187,
      strokeDashoffset: 187,
      opacity: 1,
    });
    TweenMax.set(polyline, {
      strokeDasharray: 187,
      strokeDashoffset: 187,
      opacity: 1,
    });

    TweenMax.set(polygon, { x: "-10", opacity: 0 });

    TweenMax.staggerTo(
      polyline,
      3,
      {
        ease: Expo.easeOut,
        opacity: 1,
        strokeDashoffset: 0,
      },
      0.2
    );

    TweenMax.staggerTo(
      polygon,
      1,
      {
        ease: Expo.easeOut,
        opacity: 1,
        x: "0%",
      },
      0.2
    );

    TweenMax.staggerTo(
      path,
      3,
      {
        ease: Expo.easeOut,
        opacity: 1,
        strokeDashoffset: 0,
      },
      0.2
    );

    TweenMax.staggerTo(
      rects,
      1,
      {
        startAt: { y: "-50%" },
        ease: Expo.easeOut,
        opacity: 1,
        y: "0%",
      },
      0.2,
      putclass
    );

    function putclass() {
      document.querySelector(".h2cont").classList.add("done");
    }
  }, []);

  return (
    <div className="con">
      <svg
        ref={potansiyeliniRef}
        version="1.1"
        id="write"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 596.1 97.4"
        xmlSpace="preserve"
      >
        <g>
          <rect x="585.7" y="17.5" className="st0 letteriBottom" width="10.4" height="78.7" />
          <rect x="585.7" y="0.6" className="st0 letteriTop" width="10.4" height="15" />
        </g>
        <polygon
          className="st0 lettern"
          points="561.2,64.1 538.2,0.6 528.7,0.6 528.7,96.2 539.1,96.2 539.1,32.7 562.1,96.2 571.6,96.2 571.6,0.6
	561.2,0.6 "
        />
        <g>
          <rect x="504.2" y="17.5" className="st0 letteriBottom" width="10.4" height="78.7" />
          <rect x="504.2" y="0.6" className="st0 letteriTop" width="10.4" height="15" />
        </g>
        <g className="lettere">
          <polygon
            className="st0"
            points="408.2,96.2 414.7,96.2 418.6,96.2 440.1,96.2 440.1,85.8 418.6,85.8 418.6,11 449.1,11 449.1,0.6
		408.2,0.6 	"
          />
          <rect x="420.2" y="42" className="st0" width="10.8" height="9.4" />
        </g>
        <g className="lettery">
          <polygon className="st0" points="350.8,0.6 369.7,49 369.7,96.2 380.1,96.2 380.1,49 380.4,48.1 362.1,0.6 	" />
          <polygon className="st0" points="375.5,32.1 381.1,46.4 399,0.6 387.8,0.6 	" />
        </g>
        <g>
          <rect x="331.7" y="17.5" className="st0 letteriBottom" width="10.4" height="78.7" />
          <rect x="331.7" y="0.6" className="st0 letteriTop" width="10.4" height="15" />
        </g>
        <polygon
          className="st0 lettern"
          points="254.8,64.1 231.8,0.6 222.3,0.6 222.3,96.2 232.7,96.2 232.7,32.7 255.7,96.2 265.2,96.2 265.2,0.6
	254.8,0.6 "
        />
        <polygon
          className="st0 lettera"
          points="172.6,1.1 143.1,96.9 153.9,96.9 177.1,21.6 200.3,96.9 211.1,96.9 181.5,1.1 "
        />
        <path
          className="st1 lettero"
          d="M75.8,91.7h-3c-9.8,0-17.7-7-17.7-16.7V23c0-9.8,8-17.7,17.7-17.7h0c9.8,0,17.7,8,17.7,17.7v54.9"
        />
        <path className="st1 letterp" d="M5.1,96.8V6.4h15c9.3,0,17,7.6,17,17v18.2c0,9.3-7.6,17-17,17h-2.6" />
        <path
          className="st2 letters"
          d="M301,5.2c-9.8,0-19.9,4-19.9,17.7c0,8,6.7,15.4,17.6,25.6c8.8,8.2,17,17,16.2,26.8
	c-1.1,13.5-11.5,16.9-19.9,16.9"
        />
        <polyline className="st2 lettertBottom" points="102.3,6.4 125.4,6.4 125.4,96.8 " />
        <rect x="133.4" y="1.2" className="st3 lettertTop" width="15.1" height="10.4" />
        <polyline className="st2 letterl" points="463,0.6 463,91.1 496.9,91.1 " />
      </svg>
      <div className="h2cont">
        <h2>BİZİMLE KEŞFET</h2>
      </div>
      <ButtonBase
        focusRipple
        style={{
          marginTop: 40,
        }}
        onClick={setOpen.bind(true)}
      >
        <img src={playImg} alt="" />
      </ButtonBase>
    </div>
  );
};
