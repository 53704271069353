import React, { useEffect, useRef } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {  AttrPlugin, CSSPlugin, Back, TimelineMax } from "gsap";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  scrollDown: {
    position: "absolute",
    zIndex: 1000,
    bottom: 0
  },
  icon: {
    width: "5vw",
    height: "5vw",
    color: "#fff",
  }

});

export default function ScrollDown() {
  const classes = useStyles();
  let ref = useRef();
  const tl = new TimelineMax({ repeat: -1 });

  //do not remove this
  // noinspection JSUnusedLocalSymbols
  const plugins = [CSSPlugin, AttrPlugin];

  useEffect(() => {
    tl.fromTo(
      ref.current,
      1,
      { y: -10 },
      { y: 10, ease: Back.easeOut(1, 7) }
    ).fromTo(ref.current, 1, { y: 10 }, { y: -10, ease: Back.easeOut(1, 7) });
  }, []);

  return (
    <div className={classes.scrollDown} ref={ref}>
      <ExpandMoreIcon fontSize="large" color="secondary" className={classes.icon} />
    </div>
  );
}
