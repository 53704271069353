import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { useInView } from "react-intersection-observer";

const styles = theme => ({
  root: {
    "& img": {
      opacity: "0",
      transform: "translateX(100%)",
      transition: "all .3s ease-out",
      height: "100%",
      objectFit: "cover"
    },
    display: "grid",
    gridTemplateColumns: "50% 50%",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "center",
    backgroundColor: "#EFF2F7",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr"
    }
  },
  rootVisible: {
    background: "#transparent",
    "& img": {
      position: "relative",
      transform: " translateX(0)",
      opacity: "1"
    },
    "& $content": {
      [theme.breakpoints.down("xs")]: {
        height: "20em"
      },
      display: "flex",
      alignItems: "center",

      "&:before": {
        content: "\"\"",
        position: "absolute",
        width: "100%",
        zIndex: "0",
        height: "100%",
        backgroundColor: "#273146",
        transition: "all .3s ease-out",
        top: 0,
        left: 0
      },

      "& h2": {
        position: "relative",
        opacity: "1",
        transform: "translateX(0)",
        transition: "all .3s ease-out 0.5s"
      }
    }
  },
  item: {
    alignSelf: "stretch",
    alignItems: "center",
    alignContent: "center",
    color: "white",
    display: "flex",
    position: "relative"
  },

  content: {
    margin: "auto",
    padding: "5%",
    "&:before": {
      content: "\"\"",
      position: "absolute",
      zIndex: "0",
      height: "100%",
      width: "0%",
      transition: "all .3s ease-out 0s"
    },
    "& h2": {
      fontWeight: 800,
      fontSize: "calc( 2vh + 2vw )",
      [theme.breakpoints.down("xs")]: {
        fontSize: "2em"
      },
      zIndex: "1",
      display: "inline-block",
      marginBlockStart: "calc( 2vh + 2vw )",
      marginBlockEnd: "calc( 2vh + 2vw )",
      position: "relative",
      opacity: "0",
      transform: "translateX(-20%)",
      transition: "all .3s ease-out 0s"
    },

    "& p": {
      fontWeight: 300,
      fontSize: "calc( 1vh + 0.8vw )",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1em"
      },
    }
  },

  imgContainer: {
    width: "100%",
    height: "20em",
    objectFit: "cover"
  },

  left: {
    order: 2,
    textAlign: "left"
  },

  right: {
    textAlign: "right"
  }
});

const TwoColumnBasic = ({ imageSrc, header, text, textFirst, classes }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.3
  });
  return (
    <div className={`${classes.root} ${!isVisible || classes.rootVisible}`} ref={ref}>
      <div
        className={`${classes.item} ${
          textFirst ? classes.right : classes.left
        }`}
      >
        <div className={classes.content}>
          <h2>{header}</h2>
          {text}
        </div>
      </div>
      <img
        className={`${classes.item} ${classes.imgContainer}`}
        src={imageSrc}
        alt=""
      />
    </div>
  );
};
export default withStyles(styles)(TwoColumnBasic);
