import React, { useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import decathlonYazi from "../images/home/yazi.png";
import Decathlonluyuz from "../components/home/Decathlonluyuz";
import TwoColumn from "../components/home/TwoColumn";
import TwoColumnBasic from "../components/home/TwoColumnBasic";
import ManifestCenter from "../components/home/ManifestCenter";
import withStyles from "@material-ui/core/styles/withStyles";
import Modal from "@material-ui/core/Modal";
import ScrollDown from "../components/home/ScrollDown";
import Potansiyelini from "../components/home/Potansiyelini";
import calloutImg from "../images/home/main_bg.png";
import manImg from "../images/home/man.png";
import womenImg from "../images/home/women.png";
import coupleImg from "../images/home/couple.png";
import zorluklarImg from "../images/home/sevdigimizisiyaparkenzorluklar.jpg";

const styles = (theme) => ({
  parent: {
    minHeight: "calc(100vh - 20px)",
    maxHeight: "calc(100vh - 20px)",
    overflow: "hidden",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    "@media screen and (max-height: 750px)": {
      minHeight: "750px",
    },
  },
  bg: {
    height: "100%",
    width: "100%",
    position: "absolute",
    backgroundImage: `url("${calloutImg}")`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  video: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  play: {
    top: "60vh",
    position: "absolute",
  },

  yazi: {
    width: "100%",
    objectFit: "contain",
    marginBottom: "5vh",
  },
});

const Manifesto = () => (
  <section id="manifesto">
    <TwoColumn
      imageSrc={manImg}
      header="Biz sportif, canlı ve sorumluluk sahibi büyük bir takımız!"
      text={
        <p>
          Sporu her gün daha da ulaşılabilir kılmak için sevgi ve tutkuyla çalışırız. <b>Birlikte spor yaparız.</b>{" "}
          Formalite ve mesafelerden uzak; aynı dili konuşabildiğimiz insanlarla,{" "}
          <b>samimi ve sıra dışı bir çalışma ortamına sahibiz.</b>
        </p>
      }
      textFirst={false}
    />

    <TwoColumn
      imageSrc={womenImg}
      header="Canlılık ve sorumluluk doğamızda var."
      text={
        <p>
          Burada maskesiz ve özgürce kendimiz olabiliriz. Merak ettiklerimizi keşfeder, kendi projemizi ve kariyer
          yolumuzu kendimiz çizeriz.
        </p>
      }
      textFirst={true}
    />

    <TwoColumn
      imageSrc={coupleImg}
      header="Potansiyelimizi farklı rollerde, lokasyonlarda ve takımlarda ortaya çıkarırız."
      imageWidth="70%"
      textFirst={false}
    />

    <TwoColumnBasic
      imageSrc={zorluklarImg}
      header="Sevdiğimiz işi yaparken, zorluklarla baş etmekten heyecan duyarız."
      textFirst={true}
    />

    <ManifestCenter
      header={"Otonomi, bizim olmazsa olmazımız."}
      text={
        <p>
          Hepimiz inisiyatif alır, kendi kararlarımızı uygularız. Hata yapmaktan korkmaz, hatalarımızdan birlikte
          öğrenir; birlikte gelişiriz.
        </p>
      }
      showRight
    />

    <Decathlonluyuz />

    <ManifestCenter
      header="İnanıyoruz ki,"
      text={
        <p>
          spora ve işimize duyduğumuz tutku ile insanların hayatlarına dokunur, potansiyellerini keşfetmeleri için
          onlara eşsiz bir deneyim sunarız.
        </p>
      }
      showLeft
    />
  </section>
);

const IndexPage = ({ classes }) => {
  const [open, setOpen] = useState(false);
  return (
    <Layout>
      <SEO title="Anasayfa" />

      <section id="top" className={classes.parent}>
        <Potansiyelini setOpen={setOpen} />
        <div className={classes.bg}></div>

        <Modal
          className={classes.video}
          open={open}
          onEscapeKeyDown={setOpen.bind(null, false)}
          onClose={setOpen.bind(null, false)}
        >
          <iframe
            title="Potansiyelini Keşfet Videosu"
            width="80%"
            height="80%"
            src="https://www.youtube-nocookie.com/embed/0oIhuviEPvA?rel=0&modestbranding=1&autoplay=1&iv_load_policy=3"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Modal>
        <ScrollDown />
      </section>
      <Manifesto />

      <img src={decathlonYazi} className={classes.yazi} alt="Decathlon" />
    </Layout>
  );
};

export default withStyles(styles)(IndexPage);
